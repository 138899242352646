import React from 'react';

const Hero = ({ theme }) => {
    const isKidified = theme === 'kidified'; // Check if theme is kidified to switch styles

    return (
        <div className='w-full h-[40vh] no-print'>
            {isKidified ? (
                // Kidified FunHero
                <div className="w-full h-auto bg-slate-700 p-4 m-4 border-4 rounded-md border-fuchsia-500 relative">
                    
                    {/* Star Icon on the Left with slight padding */}
                    <div className="absolute left-2 md:left-6 top-1/2 transform -translate-y-1/2 text-5xl text-white hover:scale-105 transition-transform">
                        &#9733; {/* Star symbol */}
                    </div>

                    {/* Text content, keeping the responsive width */}
                    <div className='text-white flex flex-col justify-center items-center p-4 px-6 md:px-24'> {/* Reverting to px-6 for mobile and px-24 for desktop */}
                        <h1 className='font-schoolbell font-bold text-2xl md:text-5xl drop-shadow-2xl'>
                            Math Problem Generator
                        </h1>
                        <p className='font-patrickhand max-w-[100%] md:max-w-[600px] text-sm md:text-2xl text-center text-white leading-relaxed break-words'>
                            Use this to generate math worksheets for your kids. Select at least one or more problem types
                            from those available below. We're always adding more, so check back often. Suggestions welcome!
                        </p>
                    </div>

                    {/* Smiley Icon on the Right with slight padding */}
                    <div className="absolute right-2 md:right-6 top-1/2 transform -translate-y-1/2 text-5xl text-white hover:scale-105 transition-transform">
                        &#9786; {/* Smiley face */}
                    </div>
                </div>
            ) : (
                // Adultified Hero
                <div>
                    <img className='top-20 left-0 w-full h-[40vh] object-cover no-print' src="noita-digital-MdmcpX2ofRk-unsplash.jpg" alt="/"></img>
                    <div className='absolute top-20 w-full h-[40vh] flex flex-col justify-center text-white'>
                        <div className='md:left-[10%] max-w-[1100px] m-auto absolute p-4'>
                            <h1 className='font-bold text-5xl md:text-7xl drop-shadow-2xl'>Math Problem Generator</h1>
                            <p className='max-w-[600px] drop-shadow-2xl py-4 text-xl'>
                                Use this to generate math worksheets for your kids. Select at least one or more problem types
                                from those available below. We're always adding more, so check back often. Suggestions welcome!
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Hero;