import React, { useState } from 'react';
import { FaClock, FaInfinity, FaListUl } from 'react-icons/fa';

const QandAModeSelector = ({ onSelectMode }) => {
    const [selectedMode, setSelectedMode] = useState(null);
    const [problemCount, setProblemCount] = useState(10); // Default for Bounded/Timed Mode
    const [timeLimit, setTimeLimit] = useState(300); // Default 5 minutes (in seconds) for Timed Mode

    const modes = [
        {
            name: 'Timed',
            description: 'Solve as many as you can before time runs out!',
            icon: <FaClock className="text-4xl text-blue-500" />,
            bgColor: 'bg-blue-200',
            mode: 'timed',
        },
        {
            name: 'Endless',
            description: 'Go on forever! Stop whenever you want.',
            icon: <FaInfinity className="text-4xl text-green-500" />,
            bgColor: 'bg-green-200',
            mode: 'endless',
        },
        {
            name: 'Set Number',
            description: 'Select the number of problems and aim for completion!',
            icon: <FaListUl className="text-4xl text-yellow-500" />,
            bgColor: 'bg-yellow-200',
            mode: 'bounded',
        },
    ];

    const handleStart = () => {
        if (selectedMode) {
            onSelectMode({
                mode: selectedMode,
                problemCount: selectedMode === 'timed' ? problemCount : problemCount,
                timeLimit: selectedMode === 'timed' ? timeLimit : undefined,
            });
        }
    };

    return (
        <div className="flex flex-col items-center p-4 gap-6">
            <div className="flex flex-col md:flex-row gap-6 justify-center items-center">
                {modes.map((mode) => (
                    <div
                        key={mode.name}
                        onClick={() => setSelectedMode(mode.mode)}
                        className={`${mode.bgColor} w-full md:w-1/3 p-6 rounded-lg shadow-lg cursor-pointer transition transform hover:scale-105 ${
                            selectedMode === mode.mode ? 'ring-4 ring-offset-2 ring-indigo-400' : ''
                        }`}
                    >
                        <div className="flex items-center justify-center mb-4">
                            {mode.icon}
                        </div>
                        <h3 className="text-xl font-semibold mb-2 text-center">{mode.name}</h3>
                        <p className="text-center text-gray-700">{mode.description}</p>
                    </div>
                ))}
            </div>

            {/* Additional Options for Bounded and Timed Modes */}
            {selectedMode === 'bounded' && (
                <div className="flex flex-col items-center mt-4 w-full md:w-1/3 p-4 bg-white rounded-lg shadow-lg">
                    <label className="text-lg font-semibold mb-2">Number of Problems:</label>
                    <input
                        type="number"
                        min="1"
                        max="50"
                        value={problemCount}
                        onChange={(e) => setProblemCount(Number(e.target.value))}
                        className="text-center w-20 p-2 rounded border-2 border-gray-300 focus:border-indigo-500 outline-none"
                    />
                </div>
            )}

            {selectedMode === 'timed' && (
                <div className="flex flex-col items-center mt-4 w-full md:w-1/3 gap-4">
                    {/* Number of Problems Selector */}
                    <div className="p-4 w-full bg-white rounded-lg shadow-lg">
                        <label className="text-lg font-semibold mb-2">Number of Problems:</label>
                        <input
                            type="number"
                            min="1"
                            max="50"
                            value={problemCount}
                            onChange={(e) => setProblemCount(Number(e.target.value))}
                            className="text-center w-20 p-2 rounded border-2 border-gray-300 focus:border-blue-500 outline-none"
                        />
                    </div>
                    {/* Timer Selector */}
                    <div className="p-4 w-full bg-white rounded-lg shadow-lg">
                        <label className="text-lg font-semibold mb-2">Time Limit (seconds):</label>
                        <input
                            type="number"
                            min="10"
                            max="600"
                            step="10"
                            value={timeLimit}
                            onChange={(e) => setTimeLimit(Number(e.target.value))}
                            className="text-center w-20 p-2 rounded border-2 border-gray-300 focus:border-blue-500 outline-none"
                        />
                    </div>
                </div>
            )}

            {/* Go! Tile */}
            <div
                onClick={handleStart}
                className={`w-full md:w-1/2 p-8 rounded-lg shadow-lg text-center cursor-pointer transition transform ${
                    selectedMode
                        ? 'bg-indigo-500 text-white hover:scale-105'
                        : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
            >
                {selectedMode ? 'Go!' : 'Select a Mode to Start'}
            </div>
        </div>
    );
};

export default QandAModeSelector;