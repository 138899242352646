import { default as mathC } from './Constants.js';

// Utility function to check for duplicates
export const isDuplicate = (newProblem, existingProblems) => {
  return existingProblems.some(problem => {
    if (newProblem.type === mathC.CLOCK_TYPE) {
      return problem.time === newProblem.time;
    } else {
      return problem.num1 === newProblem.num1 && problem.num2 === newProblem.num2 && problem.type === newProblem.type;
    }
  });
};

// Utility function to calculate the number of unique problems possible
export const calculateNumberOfUniqueProblems = (digits, operationType, limit) => {
  if (operationType === mathC.MULTIPLICATION_TYPE ) {
    // Times table calculation
    return limit + 1;
  }


  let min = Math.pow(10, digits - 1);
  let max = Math.min(Math.pow(10, digits) - 1, limit);

  if (digits === 1) {
    min = 0; // Adjust for 1-digit numbers
    max = Math.min(9, limit); // Ensure max is within 0-9 range
  }

  let uniqueProblemsCount = 0;

  if (operationType === '+' || operationType === '*') {
    uniqueProblemsCount = (max - min + 1) * (max - min + 1);
  } else if (operationType === '-') {
    for (let num1 = min; num1 <= max; num1++) {
      for (let num2 = min; num2 <= num1; num2++) {
        uniqueProblemsCount++;
      }
    }
  }

  return uniqueProblemsCount;
};

// Main problem generation function
export const generateProblems = (problemTypes = {}, numberOfProblems, problemTypesAndNames = {}, limit, factor) => {
  if (!problemTypes || typeof problemTypes !== 'object') return [];
  //console.time('Generate Problems');
  const newProblems = [];
  const types = Object.keys(problemTypes).filter(type => problemTypes[type]);
  let zeroCount = 0;
  let iterationCount = 0;
  const maxIterations = 10000;

  if (types.length < 1) {
    return [];
  }

  // Calculate the total number of unique problems available for the selected types
  let numberOfUniqueProblems = 0;
  types.forEach(type => {
    const newType = problemTypesAndNames[type];
    numberOfUniqueProblems += calculateNumberOfUniqueProblems(newType.digits, newType.type, limit);
  });

  // Generate unique problems or until max iterations are hit
  while (newProblems.length < numberOfProblems && iterationCount < maxIterations) {
    const randomType = types[Math.floor(Math.random() * types.length)];
    const newType = problemTypesAndNames[randomType];
    let newProblem;

    switch (randomType) {
      case 'oneDigitAddition':
      case 'twoDigitAddition':
      case 'threeDigitAddition':
      case 'oneDigitSubtraction':
      case 'twoDigitSubtraction':
      case 'threeDigitSubtraction':
      case 'oneDigitMultiplication':
      case 'twoDigitMultiplication':
      case 'threeDigitMultiplication':
      case 'division':
        newProblem = generateProblem(newType.digits, newType.type, limit);
        break;
      case 'timesTable':
        newProblem = generateTimesTableProblem(factor, limit);
        break;
      case 'clockProblem':
        const time = generateRandomTime();
        newProblem = generateClockProblem(time, newType.type);
        break;
      default:
        break;
    }

    iterationCount++;

    if (iterationCount % 20 === 0) {
      setTimeout(() => { }, 0);
    }

    // Check for duplicates
    if (!isDuplicate(newProblem, newProblems) || newProblems.length >= numberOfUniqueProblems) {

      // Check if the problem involves zero
      const isZeroBased = (newProblem.num1 === 0 || newProblem.num2 === 0);

      // Only add the zero-based problem if it's the first occurrence
      if (isZeroBased) {
        if (zeroCount < 1) {
          zeroCount++; // Increment zeroCount for the first zero-based problem
          newProblems.push(newProblem); // Add to problems
        }
      } else {
        // Add the problem if it's not zero-based
        newProblems.push(newProblem);
      }
    }
  }

  // Allow duplicates if unique problems are exhausted, but still respect the zero limit
  while (newProblems.length < numberOfProblems) {
    const randomType = types[Math.floor(Math.random() * types.length)];
    const newType = problemTypesAndNames[randomType];
    let newProblem = generateProblem(newType.digits, newType.type, limit);

    // Check for zero and respect the zeroCount limit
    const isZeroBased = (newProblem.num1 === 0 || newProblem.num2 === 0);
    if (isZeroBased && zeroCount >= 1) {
      continue; // Skip this problem if zero has already been used
    }

    if (isZeroBased) {
      zeroCount++; // Increment zeroCount if adding a zero-based problem
    }

    newProblems.push(newProblem);
  }

  //console.timeEnd('Generate Problems');
  return newProblems;  // Return the problems
};

// Problem generation helpers
export const generateProblem = (digits, type, limit) => {
  let num1;
  let num2;
  let answer;

  if (type === mathC.DIVISION_TYPE) {
    const divisor = Math.floor(Math.random() * 12) + 1;
    const quotient = Math.floor(Math.random() * 13);
    num1 = divisor * quotient;
    num2 = divisor;
    answer = quotient; // Integer result for division
  } else {
    switch (digits) {
      case 1:
        num1 = Math.floor(Math.random() * 10);
        break;
      case 2:
        num1 = Math.floor(Math.random() * 90) + 10;
        break;
      case 3:
        num1 = Math.floor(Math.random() * 900) + 100;
        break;
      default:
        console.error("Invalid digits");
        return;
    }

    const maxNum2 = Math.pow(10, digits) - 1;
    num2 = Math.floor(Math.random() * Math.min(limit + 1, maxNum2 + 1));

    if (type === mathC.SUBTRACTION_TYPE || type === mathC.DIVISION_TYPE) {
      if (num1 < num2) {
        [num1, num2] = [num2, num1];
      }
    }

    // Calculate answer based on type
    switch (type) {
      case mathC.ADDITION_TYPE:
        answer = num1 + num2;
        break;
      case mathC.SUBTRACTION_TYPE:
        answer = num1 - num2;
        break;
      case mathC.MULTIPLICATION_TYPE:
        answer = num1 * num2;
        break;
      default:
        break;
    }
  }

  // Return the problem with the correct answer included
  return { type, num1, num2, answer };
};

// Additional helper functions
export const generateRandomTime = () => {
  let hours = Math.floor(Math.random() * 12);
  const minutes = Math.floor(Math.random() * 12) * 5;

  // Convert hour `0` to `12`
  hours = hours === 0 ? 12 : hours;

  return `${hours}:${minutes.toString().padStart(2, '0')}`;
};


export const generateTimesTableProblem = (factor, limit) => {
  let num1 = getRandomIntInclusive(0, limit);
  let num2 = factor;
  let answer = num1 * num2;

  return {
    type: mathC.MULTIPLICATION_TYPE,
    num1,
    num2,
    answer,
  };
};

export const generateClockProblem = (time, type) => {
  return {
    type,
    time,
    answer: time,
  };
};

// Random integer helper
function getRandomIntInclusive(min, max) {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled);  // Inclusive min and max
}