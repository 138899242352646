import React, { useEffect, useRef } from 'react';

const AnswerInput = ({ problemType, userAnswer, setUserAnswer, onKeyDown }) => {
    const inputRef = useRef(null);

    const handleInputChange = (e) => {
        let input = e.target.value;

        // Remove non-numeric characters for both clock and non-clock problems
        input = input.replace(/\D/g, '');

        if (problemType === 'clock') {
            // Format input to "hh:mm" if it's a clock problem and has at least 3 characters
            if (input.length >= 3) {
                input = `${input.slice(0, 2)}:${input.slice(2, 4)}`;
            }
        } else {
            // Limit to 10 digits for non-clock problems
            input = input.slice(0, 10);
        }

        setUserAnswer(input);
    };

    // Auto-focus input on mount and whenever input value changes
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [userAnswer]);

    useEffect(() => {
        // Autofocus and reset cursor position for clock problems
        if (problemType === 'clock' && document.activeElement) {
            document.activeElement.setSelectionRange(0, 0); // Reset cursor to start
        }
    }, [problemType]);

    return (
        <div className="input-area flex flex-col items-center mb-4">
            <input
                ref={inputRef}
                type='tel'
                placeholder={problemType === 'clock' ? "00:00" : "Your answer"}
                value={userAnswer}
                onChange={handleInputChange}
                onKeyDown={onKeyDown}
                autoFocus
                className="answer-input text-center p-3 mb-3 w-full max-w-xs border rounded-md focus:outline-none focus:border-indigo-500"
            />
        </div>
    );
};

export default AnswerInput;