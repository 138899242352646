import React from 'react';
import { FaXTwitter, FaArrowUpRightFromSquare, FaRegEnvelope } from 'react-icons/fa6'

const Footer = ({ theme }) => {
    const isKidified = theme === 'kidified'; // Check if theme is kidified to switch styles

    return (
        <div className='print-footer text-sm footer-div w-full mt-1 bg-gray-900 text-gray-300 py-8 px-4'>
            <div className='print-footer grid grid-cols-3 max-w-[1400px] px-4 py-8 mx-auto text-gray-500'>
                <div className='pl-4 text-left'>
                    <p className={`hover:underline ${isKidified ? 'decoration-fuchsia-500 hover:text-white' : 'decoration-sky-500 hover:text-white'}`}>
                        &copy; 2024 Math Problem Generator. All rights reserved.
                    </p>
                </div>
                <div className='flex justify-center px-4'>
                    <ul>
                        <li>
                            <a href="https://x.com/mymathsheets"
                                className='hover:underline decoration-sky-500 hover:text-white flex no-print'
                                rel="noreferrer" target="_blank">
                                <FaXTwitter size={16} />
                                <FaArrowUpRightFromSquare color={isKidified ? "#d946ef" : "#00ffff"} size={12} />
                            </a>
                        </li>
                        <li>
                            <a className={`hover:underline flex no-print ${isKidified ? 'decoration-fuchsia-500 hover:text-white' : 'decoration-sky-500 hover:text-white'}`}
                                href="mailto:hello@mymathsheets.com"
                                rel="noreferrer" target="_blank">
                                hello@mymathsheets.com
                                <FaRegEnvelope color={isKidified ? "#d946ef" : "#00ffff"} size={16} />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='pr-4 text-right'>
                    <a className={`hover:underline ${isKidified ? 'decoration-fuchsia-500 hover:text-white' : 'decoration-sky-500 hover:text-white'}`}
                        href="https://mymathsheets.com">mymathsheets.com</a>
                </div>
            </div>
        </div>
    );
};

export default Footer;