import React from 'react';
import { useEffect } from 'react';
import Hero from './Hero.jsx';
import ProblemTypeButtons from './ProblemTypeButtons.jsx';
import Settings from './Settings.jsx';
import GenerateButtons from './GenerateButtons.jsx';
import ProblemsGrid from './ProblemsGrid.jsx';
import Header from './Header.jsx';
import Footer from './Footer.jsx';
import Affirmation from './Affirmation.jsx';
import FeedbackWidget from './FeedBackWidget.jsx';
import Snackbar from './Snackbar.jsx';
import ThemeToggle from './ThemeToggle.jsx';
import { logPageView } from '../analytics';

const Home = ({
    theme,
    toggleTheme,
    problemTypes,
    problemTypesAndNames,
    handleProblemTypeChange,
    buttonClasses,
    numberOfProblems,
    setNumberOfProblems,
    factor,
    setFactor,
    limit,
    setLimit,
    generateProblems,
    printProblems,
    problems,
    showSnackbar,
    snackbar,
    handleCloseSnackbar
}) => {
    useEffect(() => {
        logPageView('/home');
    }, []);

    return (
        <div className="home-container">
            <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
            <Hero theme={theme} />
            <ProblemTypeButtons
                theme={theme}
                problemTypes={problemTypes}
                problemTypesAndNames={problemTypesAndNames}
                handleProblemTypeChange={handleProblemTypeChange}
                buttonClasses={buttonClasses}
            />
            <Settings
                numberOfProblems={numberOfProblems}
                setNumberOfProblems={setNumberOfProblems}
                factor={factor}
                setFactor={setFactor}
                limit={limit}
                setLimit={setLimit}
                theme={theme}
            />
            <GenerateButtons
                generateProblems={generateProblems}
                printProblems={printProblems}
                numberOfProblems={numberOfProblems}
                theme={theme}
            />
            <Header />
            <FeedbackWidget theme={theme} showSnackbar={showSnackbar} />
            <Affirmation />
            <ProblemsGrid problems={problems} />
            <Snackbar
                message={snackbar.message}
                type={snackbar.type}
                isVisible={snackbar.isVisible}
                onClose={handleCloseSnackbar}
            />
            <Footer theme={theme} />
        </div>
    );
};

export default Home;