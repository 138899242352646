import React, { useState, useEffect, useRef } from 'react';

const CustomDropdown = ({ options, selectedOption, setSelectedOption, theme }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative w-full" style={{ zIndex: isOpen ? 10 : 1 }}>
      {/* Dropdown Button */}
      <div
        className={`${theme === 'kidified'
            ? 'bg-yellow-200 border-2 border-pink-300 rounded-lg px-4 py-2 cursor-pointer shadow-md hover:bg-orange-200 transition-all duration-300'
            : 'bg-gray-200 border-2 border-gray-400 rounded-lg px-4 py-2 cursor-pointer shadow-sm hover:bg-gray-300 transition-all duration-300'
          } flex justify-between items-center`}
        onClick={handleToggleDropdown}
      >
        {selectedOption !== null ? selectedOption : 'Select an option'}
        <span className="ml-2 text-lg">&#x25BC;</span> {/* Dropdown arrow */}
      </div>

      {/* Dropdown Content */}
      <div
        className={`absolute w-full bg-white border-2 ${theme === 'kidified' ? 'border-pink-300' : 'border-gray-400'
          } rounded-lg shadow-lg mt-2 transition-all duration-300 ease-in-out ${isOpen ? 'max-h-60 opacity-100' : 'max-h-0 opacity-0'
          } overflow-hidden`}
        style={{ transition: 'max-height 0.4s ease-in-out, opacity 0.3s ease' }}
      >
        <div className="max-h-60 overflow-y-auto">  {/* Added for scrolling */}
          {options.map((option, index) => (
            <div
              key={index}
              className={`px-4 py-2 cursor-pointer border-b ${theme === 'kidified' ? 'border-pink-300 hover:bg-orange-100' : 'border-gray-300 hover:bg-gray-100'
                } transition-colors duration-200`}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomDropdown;