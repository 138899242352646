import React from 'react';

const LongDivisionProblem = ({ dividend, divisor }) => {
    return (
        <div className="flex items-center problem">
            <div className="relative flex items-center">
                {/* Divisor - Adjusted Position */}
                <span className="absolute -left-6 top-1/2 transform -translate-y-1/2 text-xl flex-shrink-0">{divisor}</span>
                {/* SVG for Long Division Bracket */}
                <svg className="long-division-bracket flex-shrink-0" viewBox="0 0 50 30" preserveAspectRatio="xMinYMin meet">
                    <line x1="5" y1="0" x2="5" y2="25" stroke="black" strokeWidth="2" />
                    <line x1="5" y1="1" x2="50" y2="1" stroke="black" strokeWidth="2" />
                </svg>
                {/* Dividend */}
                <div className="absolute top-0.5 left-4">
                    <span className="text-xl">{dividend}</span>
                </div>
            </div>
        </div>
    );
};

export default LongDivisionProblem;