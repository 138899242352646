import React from 'react';
import { default as mathC } from '../Constants';
import CustomDropdown from './CustomDropdown';

const Settings = ({ numberOfProblems, setNumberOfProblems, factor, setFactor, limit, setLimit, theme }) => {

  // Options for dropdowns
  const numberOfProblemsOptions = [
    mathC.PRINT_LENGTH_20,
    mathC.PRINT_LENGTH_50,
    mathC.PRINT_LENGTH_100
  ];

  const factorOptions = [...Array(limit + 1).keys()]; // Creates options 0 to limit
  const limitOptions = [
    ...Array(21).keys(), // Creates options 0 to 20
    50, 100, 200, 300 // Adds 50, 100, 200, 300 options
  ];

  return (
    <div className="mb-4 settings flex-grow grid grid-cols-1 md:grid-cols-3 gap-4">
      {/* Number of Problems Dropdown */}
      <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">
        <label className="w-full md:w-1/2 text-center md:text-right">Number of problems per page:</label>
        <div className="w-1/2 md:w-1/2">
          <CustomDropdown
            options={numberOfProblemsOptions}
            selectedOption={numberOfProblems}
            setSelectedOption={setNumberOfProblems}
            theme={theme}
          />
        </div>
      </div>

      {/* Times Table Factor Dropdown */}
      <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">
        <label className="w-full md:w-1/2 text-center md:text-right text-lg font-bold text-gray-800 bg-white bg-opacity-80 px-3 py-2 rounded-lg mb-2 inline-block shadow-sm">
          Times Table Factor:
        </label>
        <div className="w-1/2 md:w-1/2">
          <CustomDropdown
            options={factorOptions}
            selectedOption={factor}
            setSelectedOption={setFactor}
            theme={theme}
          />
        </div>
      </div>

      {/* Largest Value Dropdown */}
      <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">
        <label className="w-full md:w-1/2 text-center md:text-right">Largest value to add or multiply:</label>
        <div className="w-1/2 md:w-1/2">
          <CustomDropdown
            options={limitOptions}
            selectedOption={limit}
            setSelectedOption={setLimit}
            theme={theme}
          />
        </div>
      </div>
    </div>
  );
};

export default Settings;