import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faTimes, faClock, faDivide } from '@fortawesome/free-solid-svg-icons';
import { logEvent } from '../analytics';

const icons = {
  oneDigitAddition: faPlus,
  oneDigitSubtraction: faMinus,
  oneDigitMultiplication: faTimes,
  clockProblem: faClock,
  twoDigitAddition: faPlus,
  twoDigitSubtraction: faMinus,
  twoDigitMultiplication: faTimes,
  threeDigitAddition: faPlus,
  threeDigitSubtraction: faMinus,
  threeDigitMultiplication: faTimes,
  division: faDivide,
  timesTable: faTimes,
};

const ProblemTypeButtons = ({ theme, problemTypes, problemTypesAndNames, handleProblemTypeChange, buttonClasses }) => {
  const [hoverAngles, setHoverAngles] = useState({});

  const handleMouseEnter = (key) => {
    // Generate a random angle between -5 and 5 degrees for kidified theme
    if (theme === 'kidified') {
      const randomAngle = (Math.random() * 10 - 5).toFixed(2); // [-5, 5]
      setHoverAngles((prevState) => ({
        ...prevState,
        [key]: randomAngle,
      }));
    }
  };

  const handleMouseLeave = (key) => {
    // Reset the angle when the mouse leaves the button
    setHoverAngles((prevState) => ({
      ...prevState,
      [key]: 0,
    }));
  };

  const handleButtonClick = (key) => {
    const problemType = problemTypesAndNames[key].text;

    logEvent('UI Interactions', 'Problem Generation', problemType, 1);
    
    // Logic for changing the problem type
    handleProblemTypeChange(key);
  };

  const renderButtons = Object.keys(problemTypes).map((key, index) => {
    return (
      <button
        key={index}
        onClick={() => handleButtonClick(key)}
        className={`${buttonClasses(key)}`}
        style={theme === 'kidified' ? { transform: `rotate(${hoverAngles[key] || 0}deg)` } : {}} // Apply hover angle only for kidified
        onMouseEnter={() => handleMouseEnter(key)} // Set random angle on hover
        onMouseLeave={() => handleMouseLeave(key)} // Reset angle on mouse leave
      >
        {theme === 'kidified' && <FontAwesomeIcon icon={icons[key]} className="fa-icon mr-2" />} {/* Show icons only in kidified mode */}
        {problemTypesAndNames[key].text}
      </button>
    );
  });

  return (
    <div className="justify-items-center mb-4 grid grid-cols-2 md:grid-cols-3">
      {renderButtons}
    </div>
  );
};

export default ProblemTypeButtons;