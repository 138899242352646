import './App.css';
import React, { useState, useEffect, useCallback } from 'react';
import { default as mathC } from './Constants.js';
import { generateProblems } from './mathUtils';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home.js';
import InteractiveSession from './components/InteractiveSession.jsx';
import { initGA, logPageView } from './analytics';



const App = () => {

  const [theme, setTheme] = useState('adultified'); // Default theme is "adultified"
  const [factor, setFactor] = useState(1);
  const [limit, setLimit] = useState(20);
  const [snackbar, setSnackbar] = useState({ isVisible: false, message: '', type: '' });

  // Function to show the Snackbar
  const showSnackbar = (message, type) => {
    setSnackbar({ isVisible: true, message, type });
  };

  // Function to close the Snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ isVisible: false, message: '', type: '' });
  };

  /* load google analytics */
  useEffect(() => {
    initGA();
    logPageView(window.location.pathname + window.location.search);
  }, []);

  // Apply the class to the body based on the current theme
  useEffect(() => {
    document.body.className = theme; // Replace body class with the current theme
    return () => {
      document.body.className = ''; // Clean up by removing the class when the component unmounts
    };
  }, [theme]); // Re-run this effect whenever the theme changes

  const problemTypesAndNames = {
    oneDigitAddition: { text: "1-Digit Addition", digits: 1, type: mathC.ADDITION_TYPE },
    twoDigitAddition: { text: "2-Digit Addition", digits: 2, type: mathC.ADDITION_TYPE },
    threeDigitAddition: { text: "3-Digit Addition", digits: 3, type: mathC.ADDITION_TYPE },
    oneDigitSubtraction: { text: "1-Digit Subtraction", digits: 1, type: mathC.SUBTRACTION_TYPE },
    twoDigitSubtraction: { text: "2-Digit Subtraction", digits: 2, type: mathC.SUBTRACTION_TYPE },
    threeDigitSubtraction: { text: "3-Digit Subtraction", digits: 3, type: mathC.SUBTRACTION_TYPE },
    oneDigitMultiplication: { text: "1-Digit Multiplication", digits: 1, type: mathC.MULTIPLICATION_TYPE },
    twoDigitMultiplication: { text: "2-Digit Multiplication", digits: 2, type: mathC.MULTIPLICATION_TYPE },
    threeDigitMultiplication: { text: "3-Digit Multiplication", digits: 3, type: mathC.MULTIPLICATION_TYPE },
    division: { text: "Division", digits: 1, type: mathC.DIVISION_TYPE },
    timesTable: { text: "Times Table Problem", factor: factor, limit: limit, type: mathC.MULTIPLICATION_TYPE },
    clockProblem: { text: "Clock Problems", digits: 0, type: mathC.CLOCK_TYPE }
  };

  const [problemTypes, setProblemTypes] = useState({
    oneDigitAddition: true,
    twoDigitAddition: false,
    threeDigitAddition: false,
    oneDigitSubtraction: false,
    twoDigitSubtraction: false,
    threeDigitSubtraction: false,
    oneDigitMultiplication: false,
    twoDigitMultiplication: false,
    threeDigitMultiplication: false,
    division: false,
    timesTable: false,
    clockProblem: false
  });

  const [numberOfProblems, setNumberOfProblems] = useState(20);
  const [problems, setProblems] = useState([]);

  // Wrap `applyPrintClass` in `useCallback` to prevent redefinition on every render
  const applyPrintClass = useCallback(() => {
    const root = document.documentElement;
    root.classList.remove('print-28', 'print-50', 'print-100');
    if (numberOfProblems === mathC.PRINT_LENGTH_20) {
      root.classList.add('print-28');
    } else if (numberOfProblems === mathC.PRINT_LENGTH_50) {
      root.classList.add('print-50');
    } else if (numberOfProblems === mathC.PRINT_LENGTH_100) {
      root.classList.add('print-100');
    }
  }, [numberOfProblems]);

  // Use only one `useEffect` hook, since `applyPrintClass` is memoized
  useEffect(() => {
    applyPrintClass();
  }, [applyPrintClass, numberOfProblems]);

  const handleProblemTypeChange = (type) => {
    setProblemTypes({
      ...problemTypes,
      [type]: !problemTypes[type],
    });
  };

  const printProblems = () => {
    window.print();
  };

  const buttonClasses = useCallback((type) => {
    if (theme === 'kidified') {
      // Kid theme: larger buttons, more padding, icons
      return `
      font-fredoka font-semibold rounded-xl text-lg px-4 py-2 m-2 rounded w-3/4 border
        ${problemTypes[type] ? 'bg-pink-500 text-white shadow-md' : 'bg-yellow-200 text-black'}
        md:hover:bg-pink-400 transition-all ease-in-out transform hover:shadow-lg
        transition-colors
    `;
    } else {
      // Adult theme: smaller buttons, less padding, no icons
      return `
        font-semibold px-4 py-2 m-2 rounded w-3/4
        ${problemTypes[type] ? 'bg-blue-200' : 'bg-gray-200'}
        md:hover:bg-blue-100
        transition-colors
      `;
    }
  }, [theme, problemTypes]);

  const toggleTheme = () => {
    setTheme(theme === 'kidified' ? 'adultified' : 'kidified');
  };

  // Updated Generate Problems Handler
  const handleGenerateProblems = () => {
    const problems = generateProblems(problemTypes, numberOfProblems, problemTypesAndNames, limit, factor);
    setProblems(problems);  // Update the problems state
  };

  const handleEndSession = (correctAnswers, incorrectAnswers) => {
    showSnackbar(`Session ended! Correct: ${correctAnswers}, Incorrect: ${incorrectAnswers}`, 'info');
  };

  return (
    <Router>
      <div className={`app-container ${theme}`}>
        <div className="flex flex-col min-h-screen p-4">
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  theme={theme}
                  toggleTheme={toggleTheme}
                  problemTypes={problemTypes}
                  problemTypesAndNames={problemTypesAndNames}
                  handleProblemTypeChange={handleProblemTypeChange}
                  buttonClasses={buttonClasses}
                  numberOfProblems={numberOfProblems}
                  setNumberOfProblems={setNumberOfProblems}
                  factor={factor}
                  setFactor={setFactor}
                  limit={limit}
                  setLimit={setLimit}
                  generateProblems={handleGenerateProblems}
                  printProblems={printProblems}
                  problems={problems}
                  showSnackbar={showSnackbar}
                  snackbar={snackbar}
                  handleCloseSnackbar={handleCloseSnackbar}
                />
              }
            />
            <Route
              path="/qanda-session"
              element={
                <InteractiveSession
                  problemTypes={problemTypes}
                  problemTypesAndNames={problemTypesAndNames}
                  onEndSession={handleEndSession}
                  limit={limit}
                  factor={factor}
                />
              }
            />
          </Routes>
        </div>
      </div>
    </Router>);
};

export default App;
