import React from 'react';
import { logEvent } from '../analytics';

const ThemeToggle = ({ theme, toggleTheme }) => {
  const handleToggle = () => {
    const themeType = theme === 'kidified' ? 'Kid Mode' : 'Adult Mode';

    logEvent('UI Interactions', 'Theme Toggled', themeType);

    toggleTheme();
  };

  return (
    <div className="toggle-container text-center mb-5 relative">
      <button
        onClick={handleToggle}
        className={`no-print toggle-button ${theme === 'kidified' ? 'kidified' : 'adultified'}`}
      >
        {theme === 'kidified' ? 'Back to Boring' : 'Click Here for Fun'}
      </button>
    </div>
  );
};

export default ThemeToggle;