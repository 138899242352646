import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { logEvent } from '../analytics';


const GenerateButtons = ({ generateProblems, printProblems, numberOfProblems, theme }) => {
  // Local state to track if a worksheet has been generated
  const [worksheetGenerated, setWorksheetGenerated] = useState(false);

  return (
    <div className="flex justify-between items-center mb-4 w-full">
      {/* Left Aligned Section for Generate Worksheet and Print */}
      <div className="flex">
        <button
          onClick={() => {
            logEvent('Problem Generation', 'Generate Problems', '', numberOfProblems);
            generateProblems();
            setWorksheetGenerated(true); // Enable Print button after generation
          }}
          className={`border py-2 px-4 rounded-full hover:shadow-xl text-white transition-colors ${theme === 'kidified' ? 'bg-orange-400 hover:bg-green-600' : 'bg-green-500 hover:bg-green-600'}`}
        >
          Generate Worksheet
        </button>
        <button
          onClick={() => {
            if (worksheetGenerated) {
              printProblems();
              logEvent('User Actions', 'Print Button Click', 'Print');
            }
          }}
          disabled={!worksheetGenerated}
          className={`border py-2 px-4 rounded-full text-white transition-colors ml-2 print-button ${worksheetGenerated ? (theme === 'kidified' ? 'bg-red-500 hover:bg-blue-600' : 'bg-sky-500 hover:bg-blue-600') : 'bg-gray-400 cursor-not-allowed'
            }`}
        >
          Print
        </button>
      </div>

      {/* Right Aligned Section for Start Interactive Session with Extra Padding */}
      <Link
        to="/qanda-session"
        className={`border py-2 px-4 rounded-full hover:shadow-xl text-white transition-colors ${theme === 'kidified' ? 'bg-purple-400 hover:bg-purple-500' : 'bg-indigo-500 hover:bg-indigo-600'}`}
        style={{ marginRight: '32px' }} // Adjust margin as needed to avoid overlap
      >
        Start Interactive Session
      </Link>
    </div>
  );
};

export default GenerateButtons;