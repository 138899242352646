import React from 'react';
import ClockSVG from './ClockSVG';
import { default as mathC } from '../Constants';
import LongDivisionProblem from './LongDivisionProblems';

const ProblemsGrid = ({ problems }) => {
  let attr;
  switch (problems.length) {
    case mathC.PRINT_LENGTH_20:
      attr = "grid-cols-4";
      break;
    case mathC.PRINT_LENGTH_50:
      attr = "grid-cols-5";
      break;
    case mathC.PRINT_LENGTH_100:
      attr = "grid-cols-10";
      break;
    default:
      console.warn("unknown printing size");
      break;
  }

  return (
    <div className={`flex-grow grid ${attr} gap-4 problems-grid text-xl unicorn-background`}>
      {problems.map((problem, index) => (
        <div key={index} className="problem flex flex-col content-start">
          {problem.type === mathC.CLOCK_TYPE ? (
            <ClockSVG time={problem.time} />
          ) : problem.type === mathC.DIVISION_TYPE ? (
            // Render the custom LongDivisionProblem for division
            <LongDivisionProblem dividend={problem.num1} divisor={problem.num2} />
          ) : (
            // For other problems, render them as before
            <div className="num1">{problem.num1}</div>
          )}
          <div className="operator-num2">
            {problem.type === mathC.CLOCK_TYPE || problem.type === mathC.DIVISION_TYPE ? (
              <span></span>
            ) : (
              <span>{problem.type}</span>
            )}
            {problem.type !== mathC.DIVISION_TYPE && <span>{problem.num2}</span>}
          </div>
          <div className="line-container">
            {problem.type === mathC.CLOCK_TYPE ? (
              <div className="clock-line"></div>
            ) : problem.type === mathC.DIVISION_TYPE ? (
              <div className="long-division-line"></div>
            ) : (
              <div className="line"></div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProblemsGrid;