import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown, faMeh, faSmile, faGrinBeam, faLaughBeam } from '@fortawesome/free-regular-svg-icons';
import { logEvent } from '../analytics';


const FeedbackWidget = ({ theme, showSnackbar }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedRating, setSelectedRating] = useState(null);
    const [email, setEmail] = useState('');
    const [feedback, setFeedback] = useState('');
    //const [captchaToken, setCaptchaToken] = useState(''); // if you're using CAPTCHA
    const [errorMessage, setErrorMessage] = useState('');

    const toggleWidget = () => {
        setIsOpen(!isOpen);

        logEvent('UI Interactions', isOpen ? 'Feedback Form Closed' : 'Feedback Form Opened');
    };

    // Define a range of emotions from sad to happy using FontAwesome icons
    const emotionIcons = [
        { icon: faFrown, label: "Very Sad" },
        { icon: faMeh, label: "Sad" },
        { icon: faSmile, label: "Neutral" },
        { icon: faGrinBeam, label: "Happy" },
        { icon: faLaughBeam, label: "Very Happy" }
    ];

    // Emojis for rating (1 to 5)
    //const emojis = ["😡", "🙁", "😐", "🙂", "😃"];

    const handleRating = (rating) => {
        setSelectedRating(rating);

        logEvent('UI Interactions', 'Feedback Rating Selected', rating);

    };

    // Real-time email validation
    const handleEmailChange = (e) => {
        const inputEmail = e.target.value;
        setEmail(inputEmail);

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(inputEmail)) {
            setErrorMessage('Please enter a valid email.');
        } else {
            setErrorMessage('');  // Clear error if the email is valid
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (errorMessage) {
            return; // Prevent form submission if there's an email error
        }

        try {
            // Track form submission event before sending the feedback
            logEvent('UI Interactions', 'Feedback Submitted', selectedRating);

            // Call the API to send the feedback
            const apiUrl = process.env.REACT_APP_SERVER_URL;
            const apiKey = process.env.REACT_APP_API_KEY;
            const response = await fetch(`${apiUrl}/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey // Replace with your actual API key
                },
                body: JSON.stringify({
                    rating: selectedRating,
                    email,
                    feedback,
                    //captchaToken // Include this only if using CAPTCHA
                })
            });

            const result = await response.json();
            if (result.success) {
                showSnackbar('Form submitted successfully!', 'success');
                logEvent('UI Interactions', 'Feedback Submission Success', selectedRating);

                // Reset the state values to clear the form
                setSelectedRating(null);
                setEmail('');
                setFeedback('');

                // Close the feedback form
                setIsOpen(false);
            } else {
                showSnackbar('Failed to submit form!', 'error');
                logEvent('UI Interactions', 'Feedback Submission Error', 0);

            }
        } catch (error) {
            showSnackbar('Error sending feedback. Please try again later.', 'error');
            logEvent('UI Interactions', 'Feedback Submission Exception', error);
        }
    };

    return (
        <div className={`no-print fixed right-0 top-1/2 transform -translate-y-1/2 z-50 ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300`}>
            {/* Widget Content */}
            <div className="w-72 bg-white p-4 shadow-lg relative">
                <h3 className={`text-xl font-semibold mb-4 ${theme === 'kidified' ? 'font-fredoka' : 'font-sans'}`}>Feedback</h3>
                {/* Smiley Rating */}
                <div className="flex justify-around mb-4">
                    {emotionIcons.map((emotion, index) => (
                        <button
                            key={index}
                            onClick={() => handleRating(index + 1)}
                            className={`text-lg ${selectedRating === index + 1 ? 'text-blue-500' : 'text-gray-500'}`}
                            aria-label={emotion.label}
                        >
                            <FontAwesomeIcon icon={emotion.icon} />
                        </button>
                    ))}
                </div>

                {/* Email Input */}
                <input
                    type="email"
                    placeholder="Your email"
                    className="w-full mb-4 p-2 border rounded-lg"
                    value={email}
                    onChange={handleEmailChange}
                    required
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                />
                {errorMessage && <p className="text-red-500">{errorMessage}</p>}

                {/* Comment Input */}
                <textarea
                    placeholder="Your comment"
                    className="w-full p-2 border rounded-lg"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    maxLength={500} // Limit feedback to 500 characters
                />
                <p className="text-right text-sm text-gray-500">{feedback.length}/500</p>

                {/* Submit Button */}
                <button
                    onClick={handleSubmit}
                    className={`w-full ${theme === 'kidified' ? 'bg-pink-500' : 'bg-blue-500'} text-white py-2 rounded-lg flex items-center justify-center mt-4`}>
                    <FontAwesomeIcon icon={faLaughBeam} className="mr-2" />
                    Submit
                </button>
            </div>

            {/* Rectangular Bar Handle */}
            <button
                className={`absolute right-full top-0 h-full ${theme === 'kidified' ? 'bg-pink-500' : 'bg-blue-500'} text-white w-12 rounded-l-lg flex items-center justify-center focus:outline-none`} onClick={toggleWidget}
                style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0' }}  // Make sure it's rectangular
            >
                <span className={`transform ${isOpen ? 'rotate-0' : 'rotate-180'} transition-transform duration-300`}>
                    →
                </span>
            </button>
        </div>
    );
};

export default FeedbackWidget;