const MY_CONSTANTS = {
    CLOCK_TYPE: "clock",
    MULTIPLICATION_TYPE: "x",
    SUBTRACTION_TYPE: "-",
    ADDITION_TYPE: "+",
    DIVISION_TYPE: "/",
    PRINT_LENGTH_20: 20,
    PRINT_LENGTH_50: 50,
    PRINT_LENGTH_100: 100
};

export default MY_CONSTANTS;