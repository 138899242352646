import { useNavigate } from 'react-router-dom';
import { logEvent } from '../analytics';

const QandASummary = ({ problems, sessionOutcome }) => {
    const navigate = useNavigate();

    const handleBackToHomeSelection = () => {
        navigate('/');
    };

    const totalProblems = problems.length;

    // Calculate correct, incorrect, and skipped counts dynamically
    const correctCount = problems.filter(problem => problem.isCorrect === true).length;
    const incorrectCount = problems.filter(problem => problem.isCorrect === false).length;
    const skippedCount = problems.filter(problem => problem.isCorrect === undefined).length;

    let message = "";
    if (sessionOutcome === "timerExpired") {
        message = `Oops! ⏰ Time’s Up! You solved ${correctCount} problems correctly! Keep it up! 🎉`;
    } else if (sessionOutcome === "completed") {
        message = `Awesome job! 🎉 You answered all ${totalProblems} questions with ${correctCount} correct!`;
    } else if (sessionOutcome === "endless") {
        message = `Great work! 🎈 You answered ${correctCount} correctly and skipped ${skippedCount}.`;
    }
    logEvent('Interactive Mode', 'Problems Completed', 'Correct', correctCount);
    logEvent('Interactive Mode', 'Problems Completed', 'Incorrect', incorrectCount);
    logEvent('Interactive Mode', 'Problems Completed', 'Skipped', skippedCount);

    return (
        <div className="w-full max-w-4xl mx-auto px-4 py-8">
            <h2 className="text-3xl font-bold text-center mb-8">{message}</h2>

            <div className="grid grid-cols-3 gap-4 mb-8">
                <div className="bg-green-100 p-4 rounded-lg text-center">
                    <div className="font-semibold text-green-700">Correct</div>
                    <div className="text-2xl">{correctCount}</div>
                </div>
                <div className="bg-red-100 p-4 rounded-lg text-center">
                    <div className="font-semibold text-red-700">Incorrect</div>
                    <div className="text-2xl">{incorrectCount}</div>
                </div>
                <div className="bg-yellow-100 p-4 rounded-lg text-center">
                    <div className="font-semibold text-yellow-700">Skipped</div>
                    <div className="text-2xl">{skippedCount}</div>
                </div>
            </div>

            {/* Display problem list and results */}
            <div className="space-y-4">
                {problems.map((problem, index) => (
                    <div
                        key={index}
                        className={`p-4 rounded-lg border ${problem.isCorrect === true ? 'bg-green-100' :
                            problem.isCorrect === false ? 'bg-red-100' :
                                'bg-yellow-100'
                            }`}
                    >
                        <div className="flex justify-between items-center">
                            <div>
                                {problem.type === 'clock' ? (
                                    <>Problem {index + 1}: {problem.time}</>
                                ) : (
                                    <>Problem {index + 1}: {problem.num1} {problem.type} {problem.num2} = {problem.solution}</>
                                )}
                            </div>
                            <div>
                                Your Answer: {problem.userAnswer || 'Not answered'}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="mt-8 text-center">
                <button
                    onClick={handleBackToHomeSelection}
                    className="px-6 py-3 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors"
                >
                    🎈 Go Back Home 🎈
                </button>
            </div>
        </div>
    );
};

export default QandASummary;