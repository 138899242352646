import React, { useState } from 'react';
import QandA from './QandA';
import QandAModeSelector from './QandAModeSelector';
import { generateProblems } from '../mathUtils';
import { useNavigate } from 'react-router-dom';
import { logEvent } from '../analytics';


const InteractiveSession = ({ problemTypes, problemTypesAndNames, limit, factor }) => {
    const [modeConfig, setModeConfig] = useState(null);
    const [problems, setProblems] = useState([]);
    const navigate = useNavigate();

    // Main back button handler
    const handleBack = () => {
        navigate('/');
    };

    // Handle mode selection and problem generation
    const handleModeSelection = (config) => {
        setModeConfig(config);
        const generatedProblems = generateProblems(
            problemTypes,
            config.mode === 'endless' ? 1 : config.problemCount || 10,
            problemTypesAndNames,
            limit,
            factor
        );
        logEvent('Interactive Session', 'Select Session Mode', config.mode);
        setProblems(generatedProblems);
    };

    // Return to mode selection within InteractiveSession without navigating
    const handleBackToModeSelector = () => {
        setModeConfig(null);
    };

    const handleEndSession = () => {
        // This function can reset the session or perform other actions
        // use this to persist session logs and results after a run
        // and to reset
        // can also be used to display session logs, but currently we're doing that 
        // in the QandA page. if we ever change that, here's a way to do that
        //setModeConfig(null);
        logEvent('Interactive Mode', 'Session End', modeConfig.mode);
        console.log("onEndSession");
    };  

    return (
        <div className="flex flex-col items-center">
            {/* Back Button */}
            <button
                onClick={modeConfig ? handleBackToModeSelector : handleBack}
                className="back-button text-white bg-green-500 px-5 py-2 rounded-full shadow-md hover:bg-green-600"
            >
                ← Back
            </button>

            {modeConfig ? (
                 <QandA
                 modeConfig={modeConfig}
                 problems={problems}
                 onBack={handleBackToModeSelector}
                 limit={limit}
                 factor={factor}
                 problemTypes={problemTypes}
                 problemTypesAndNames={problemTypesAndNames}
                 onEndSession={handleEndSession}
             />  

            ) : (
                <>
                    <h2 className="text-2xl font-bold mb-4">Select Mode</h2>
                    <QandAModeSelector onSelectMode={handleModeSelection} />
                </>
            )}
        </div>
    );
};

export default InteractiveSession;