import React, { useState, useEffect, useCallback } from 'react';
import QandASummary from './QandASummary';
import ClockSVG from './ClockSVG';
import LongDivisionProblem from './LongDivisionProblems';
import { generateProblems } from '../mathUtils';
import TimedModeTimer from './TimedModeTimer';
import AnswerInput from './AnswerInput';

const QandA = ({ modeConfig, problems, onEndSession, onBack, limit, factor, problemTypes, problemTypesAndNames }) => {
    const [currentProblemIndex, setCurrentProblemIndex] = useState(0);
    const [userAnswer, setUserAnswer] = useState('');
    const [sessionEnded, setSessionEnded] = useState(false);
    const [answeredQuestions, setAnsweredQuestions] = useState(new Set());
    const [skippedQuestions, setSkippedQuestions] = useState(new Set());
    const [dynamicProblems, setDynamicProblems] = useState(problems);
    const [sessionOutcome, setSessionOutcome] = useState("completed");

    const currentProblem = dynamicProblems.length > 0 ? dynamicProblems[currentProblemIndex] : null;

    const progressPercentage = (answeredQuestions.size / dynamicProblems.length) * 100;

    // Ends the session, handling different behavior for Endless Mode.
    // In Endless Mode, removes the last problem to prevent an incomplete entry
    // from appearing as "skipped" in the summary, then sets sessionEnded to true.
    // In other modes, simply sets sessionEnded to true without modifying the problem array.
    // Finally, triggers the onEndSession callback (if provided) to initiate any additional end-session logic.
    const handleEndSession = useCallback(() => {
        if (modeConfig.mode === 'endless') {
            setDynamicProblems((prevProblems) => {
                const trimmedProblems = prevProblems.slice(0, -1); // Remove the last problem
                setSessionEnded(true); // End the session after trimming
                return trimmedProblems; // Return the modified array
            });
        } else {
            setSessionEnded(true);
        }

        setTimeout(() => {
            onEndSession && onEndSession();
        }, 0);
    }, [modeConfig, onEndSession]);

    useEffect(() => {
        if (modeConfig?.mode === 'timed' && !sessionEnded) {
            const timer = setTimeout(() => {
                setSessionOutcome("timerExpired");
                handleEndSession();
            }, modeConfig.timeLimit * 1000);
            return () => clearTimeout(timer);
        }
    }, [modeConfig, sessionEnded, handleEndSession]);


    // Adds a new problem to the problem set in Endless Mode.
    // Generates a single new problem based on the specified problem types, 
    // adds it to the end of the dynamicProblems array, and increments the 
    // currentProblemIndex to display the new problem. Ensures that a fresh 
    // problem is always available until the session ends.
    const goToNextEndlessProblem = useCallback(() => {
        const newProblem = generateProblems(problemTypes, 1, problemTypesAndNames, limit, factor)[0];
        setDynamicProblems(prev => [...prev, newProblem]);
        setCurrentProblemIndex(prev => prev + 1);
    }, [problemTypes, problemTypesAndNames, limit, factor]);

    // Advances to the next unanswered problem in the problem set.
    // Increments the current index by one, wrapping around to the beginning if needed.
    // Skips over any problems already marked as answered or skipped, ensuring only 
    // unanswered problems are selected. Loops until an unanswered problem is found or 
    // returns to the start if all are answered or skipped.
    const goToNextProblem = useCallback(() => {
        setCurrentProblemIndex(prevIndex => {
            let nextIndex = (prevIndex + 1) % dynamicProblems.length;
            while (answeredQuestions.has(nextIndex) && skippedQuestions.has(nextIndex)) {
                nextIndex = (nextIndex + 1) % dynamicProblems.length;
            }
            return nextIndex;
        });
    }, [dynamicProblems.length, answeredQuestions, skippedQuestions]);

    // Helper function to format time consistently
    const formatTimeInput = (timeString) => {
        // Remove leading underscores or unwanted characters
        let cleanedTime = timeString.replace(/^_+/, '');

        // Split and format time components to ensure 2-digit hour
        const [hours, minutes] = cleanedTime.split(':').map((part) => part.padStart(2, '0'));

        return `${hours}:${minutes}`;
    };

    const handleAnswerSubmit = useCallback(() => {
        if (sessionEnded) return;

        const currentProblem = dynamicProblems[currentProblemIndex];
        //const solution = calculateSolution(currentProblem);

        // Check if the answer is a clock problem
        const isCorrect = currentProblem.type === 'clock'
            ? formatTimeInput(userAnswer) === formatTimeInput(currentProblem.time)
            : userAnswer === currentProblem.answer.toString();

        setDynamicProblems(prevProblems => {
            const updatedProblems = [...prevProblems];
            updatedProblems[currentProblemIndex] = {
                ...currentProblem,
                userAnswer,
                isCorrect: isCorrect,
                solution: currentProblem.answer.toString()
            };
            return updatedProblems;
        });

        setAnsweredQuestions(prev => new Set([...prev, currentProblemIndex]));
        setUserAnswer('');

        if (modeConfig.mode === 'endless') {
            goToNextEndlessProblem();
        } else if (answeredQuestions.size + 1 === dynamicProblems.length) {
            handleEndSession();
        } else {
            goToNextProblem();
        }
    }, [
        sessionEnded,
        dynamicProblems,
        currentProblemIndex,
        userAnswer,
        modeConfig.mode,
        answeredQuestions.size,
        handleEndSession,
        goToNextEndlessProblem,
        goToNextProblem
    ]);


    const handleSkip = useCallback(() => {
        if (sessionEnded) return;

        const currentProblem = dynamicProblems[currentProblemIndex];

        setDynamicProblems(prevProblems => {
            const updatedProblems = [...prevProblems];
            updatedProblems[currentProblemIndex] = {
                ...currentProblem,
                userAnswer: "Skipped",
                isCorrect: undefined, // signify it was skipped
                solution: currentProblem.answer.toString()
            };
            return updatedProblems;
        });

        setSkippedQuestions(prev => new Set([...prev, currentProblemIndex]));

        if (modeConfig.mode === 'endless') {
            goToNextEndlessProblem();
        } else {
            goToNextProblem();
        }
    }, [
        sessionEnded,
        dynamicProblems,
        currentProblemIndex,
        modeConfig.mode,
        goToNextEndlessProblem,
        goToNextProblem
    ]);

    useEffect(() => {
        if (modeConfig?.mode === 'timed' && !sessionEnded) {
            const timer = setTimeout(() => {
                handleEndSession();
            }, modeConfig.timeLimit * 1000);
            return () => clearTimeout(timer);
        }
    }, [modeConfig, sessionEnded, handleEndSession]);

    // Render component only if the current problem exists
    // Retrieve current problem based on index only if `dynamicProblems` has elements
    if (!currentProblem && !sessionEnded) return null; // Avoid rendering until a problem is ready

    return (
        <div className="flex w-full items-start">
            <div className="flex flex-col items-center flex-grow w-3/4">
                {/* Progress bar for timed and bounded modes */}
                {(modeConfig?.mode === 'timed' || modeConfig?.mode === 'bounded') && (
                    <div className="w-full px-8 my-4">
                        <div className="bg-gray-200 h-3 rounded-full overflow-hidden">
                            <div
                                className="h-full bg-green-500 rounded-full transition-all duration-300 ease-in-out"
                                style={{ width: `${progressPercentage}%` }}
                            />
                        </div>
                    </div>
                )}

                {sessionEnded ? (
                    <QandASummary
                        problems={dynamicProblems}
                        sessionOutcome={sessionOutcome}
                    />
                ) : (
                    <div className="problem-card max-w-4xl w-full px-4">
                        <h3 className="text-2xl font-bold mb-4 text-center">Mode: {modeConfig.mode}</h3>
                        <div className="text-lg text-gray-700 mb-4 text-center">
                            Answering problem {currentProblemIndex + 1} of {dynamicProblems.length}
                        </div>

                        <div className={`problem-display flex justify-center mb-4 ${currentProblem.type === 'clock'
                            ? 'text-lg'
                            : currentProblem.type === '/'
                                ? 'text-xl'
                                : 'text-3xl'
                            }`}>
                            {currentProblem.type === 'clock' ? (
                                <ClockSVG time={currentProblem.time} />
                            ) : currentProblem.type === '/' ? (
                                <LongDivisionProblem
                                    dividend={currentProblem.num1}
                                    divisor={currentProblem.num2}
                                />
                            ) : (
                                <span>
                                    {currentProblem.num1} {currentProblem.type} {currentProblem.num2}
                                </span>
                            )}
                        </div>

                        <div className="input-area flex flex-col items-center mb-4">
                            {currentProblem && (
                                <AnswerInput
                                    key={currentProblem.type} // Forces re-render on problem type change
                                    problemType={currentProblem.type}
                                    userAnswer={userAnswer}
                                    setUserAnswer={setUserAnswer}
                                    onKeyDown={(e) => e.key === 'Enter' && handleAnswerSubmit()}
                                />
                            )}
                        </div>
                        <div className="button-container flex justify-center space-x-2 mt-4">
                            <button
                                onClick={handleAnswerSubmit}
                                className="submit-button px-4 py-2 bg-green-500 text-white rounded-full hover:bg-green-600"
                            >
                                Submit
                            </button>
                            <button
                                onClick={handleSkip}
                                className="skip-button px-4 py-2 bg-yellow-500 text-white rounded-full hover:bg-yellow-600"
                            >
                                Skip
                            </button>
                            {modeConfig.mode === 'endless' && (
                                <button
                                    onClick={handleEndSession}
                                    className="finish-session-button px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600"
                                >
                                    Finish Session
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </div>

            {/* Timer positioned on the right */}
            {(!sessionEnded && modeConfig?.mode === 'timed') && (
                <div className="fixed right-4 top-40">
                    <TimedModeTimer timeLimit={modeConfig.timeLimit} />
                </div>
            )}
        </div>
    );
};

export default QandA;